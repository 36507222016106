import { useEffect } from 'react'
import { useRouter } from 'next/router'
import { authStateCommon } from '@pig-common/recoils'
import { useMutation } from '@tanstack/react-query'
import { gamesApi } from '@pig-casino/api'
import { useRecoilValue } from 'recoil'
import { pageLinkCommon } from '@pig-common/constants/page-name'
import { NProgress } from '@pig-frontend/uikit'

export default function useGameLobby() {
  const router = useRouter()
  const isAuthenticated = useRecoilValue(authStateCommon.isAuthenState)
  const { customerCode, gameToken } = useRecoilValue(authStateCommon.authState)

  useEffect(() => {
    return () => {
      NProgress.done()
    }
  }, [])

  return useMutation({
    mutationFn: (
      params: Pick<
        gamesApi.IGameLobbyParams,
        'provider' | 'isMobile' | 'gameCode'
      >,
    ) => {
      if (isAuthenticated) {
        NProgress.start()
        return gamesApi.gameLobby({ customerCode, gameToken, ...params })
      } else {
        router.push(pageLinkCommon.login)
        return null
      }
    },
    onSuccess: () => {
      NProgress.set(0.8)
    },
  })
}
